import moment from "moment";
import * as R from "ramda";
import {
  collection,
  getCountFromServer,
  query,
  where,
} from "firebase/firestore";
import { db } from "services/firebase.service";
import Text from "atoms/Text";
import { convertCmIntoFeet } from "./constants";
import { getAuth } from "services/identity.service";

export const ROLES = {
  NUTRITIONIST: "nutritionist",
  PSYCHOLOGIST: "psychologist",
  UV_COACH: "uv coach",
  CRM: "crm",
  ADMIN: "admin",
  PSORIASIS_ADMIN: "psoriasis admin",
};
const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
};

const formatDate = (date, format = "DD MMM YYYY", defaultReturnValue = "-") => {
  return date ? moment(date).format(format) : defaultReturnValue;
};

export const getExecutionDate = (task, tasksList) => {
  const format = "DD MMM YYYY";
  const exeDate = formatDate(
    new Date(task.executionDate).setHours(9, 0, 0, 0),
    "dddd, DD MMM YYYY hh:mm A"
  );
  const candidateJoiningDate = getValueByPath(
    task,
    "CampaignInstance.Candidate.estimatedJoiningDate"
  );
  if (task.type !== "meeting") {
    return exeDate;
  }
  if (R.isNil(task.prevTaskExeDate) && R.isNil(task.nextTaskExeDate)) {
    return exeDate;
  }
  const startDate = task.prevTaskExeDate
    ? moment(task.prevTaskExeDate).add(1, "days").format(format)
    : formatDate(task.executionDate);
  const endDate = task.nextTaskExeDate
    ? moment(task.nextTaskExeDate).subtract(1, "days").format(format)
    : formatDate(
        candidateJoiningDate
          ? moment(candidateJoiningDate).subtract(1, "days").format(format)
          : task.executionDate
      );
  if (moment(startDate).isSameOrAfter(moment(endDate))) {
    return formatDate(exeDate, "dddd, DD MMM YYYY hh:mm A");
  }
  return `${startDate} - ${endDate}`;
};

const throwAPIError = ({ error }) => {
  if (error?.response.status === 401) {
    throw error.data.message;
  }
};

export const checkFor401Error = (error) => {
  const { status } = error.response;
  return status === 401;
};

export const checkForTokenExpiredError = (error) => {
  const { status, data } = error.response;
  return status === 401 && data.message === "Token Expired";
};

export const getDateDifference = ({ dateOne, dateTwo, diffIn = "days" }) => {
  return Math.max(moment(dateOne).diff(moment(dateTwo), diffIn), 0);
};

const getDateRange = (timeFrame) => {
  let set = { hour: 0, minute: 0, second: 0, millisecond: 0 };
  let format = "YYYY-MM-DD HH:mm:ss";
  return R.cond([
    [
      R.equals("lastWeek"),
      () => ({
        fromDate: moment().subtract(7, "days").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("last15days"),
      () => ({
        fromDate: moment().subtract(15, "days").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("lastMonth"),
      () => ({
        fromDate: moment().subtract(1, "month").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("last90days"),
      () => ({
        fromDate: moment().subtract(90, "days").set(set).format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("lastSixMonths"),
      () => ({
        fromDate: moment()
          .subtract(5, "month")
          .startOf("month")
          .set(set)
          .format(format),
        toDate: moment().set(set).format(format),
      }),
    ],
    [
      R.equals("custom"),
      () => ({
        fromDate: moment(timeFrame.dates[0]).set(set).format(format),
        toDate: moment(timeFrame.dates[1]).set(set).format(format),
      }),
    ],
  ])(timeFrame.type);
};
export { capitalizeFirstLetter, formatDate, throwAPIError, getDateRange };

export const getWhatsAppBody = (type, newMeetingTemplate = false) => {
  if (type === "content") {
    return `Dear {{1}},
    We have something exciting for you to check out on our ANSR Loop platform, as we get closer to you joining us at {{2}}.
    
    To view {{3}}, sign in to your ANSR Loop account ({{4}}). Keep an eye out for further interesting information.
    
    Cheers to reading!
    Team {{5}}`;
  } else if (type === "meeting") {
    if (newMeetingTemplate) {
      return `Dear {{1}},

As your joining date approaches, we have a meeting set up for you with your {{2}} - {{3}} 
For further information, check your Loop account({{4}}) after logging in.

We're looking forward to meeting you!
Team {{5}}`;
    }
    return `Dear {{1}},
    We have a meeting set up for you with {{2}} on {{3}} as your joining date approaches.
    For further information, check your ANSR Loop account({{4}}) after logging in.
    
    We’re looking forward to meeting you!
    Team {{5}}`;
  } else if (type === "give_away" || type == "survey") {
    return `Dear {{1}}
    We have a surprise for you on the way as your joining date approaches! For further information, check your ANSR Loop account ({{2}}) after logging in.
    
    Team {{3}}`;
  }
};

export const getUTCDateWithTime = (date, [h, m, s]) => {
  const mo = moment(date);
  mo.set("hour", h);
  mo.set("minute", m);
  mo.set("second", s);
  return mo.utc().toDate();
};

export const snakeToCamel = (str) => {
  return str.replace(/(?!^)_(.)/g, (_, char) => char.toUpperCase());
};

export const removeNotSelectedFilterData = (filters) => {
  Object.keys(filters).forEach((key) => {
    if (R.isNil(filters[key].value) || R.isEmpty(filters[key].value)) {
      delete filters[key];
    } else if (
      R.is(Object, filters[key].value) &&
      R.hasPath(["fromDate"], filters[key].value) &&
      R.isNil(filters[key].value.fromDate)
    ) {
      delete filters[key];
    } else if (
      R.is(Object, filters[key].value) &&
      R.hasPath(["min"], filters[key].value) &&
      R.isNil(filters[key].value.min) &&
      R.isNil(filters[key].value.max)
    ) {
      delete filters[key];
    }
  });
  return filters;
};

export const getSelectedFilters = (filters) => {
  const newFilters = { ...filters };
  const customFilters = newFilters.customAttributeFilters || {};
  const selectedFilterData = {
    ...removeNotSelectedFilterData(newFilters),
    customAttributeFilters: {
      ...removeNotSelectedFilterData(customFilters),
    },
  };
  if (R.isEmpty(selectedFilterData.customAttributeFilters)) {
    delete selectedFilterData["customAttributeFilters"];
  }
  return selectedFilterData;
};

export const getInitialFilterData = (preSelectedFilters) => {
  return {
    sessionStatus: {
      label: "Status",
      type: "multi select",
      value: preSelectedFilters?.sessionStatus
        ? preSelectedFilters.sessionStatus.value
        : [],
    },
    program: {
      label: "Program",
      type: "multi select",
      value: preSelectedFilters?.program
        ? preSelectedFilters.program.value
        : [],
    },
    nutritionist: {
      label: "Nutritionist",
      type: "multi select",
      value: preSelectedFilters?.nutritionist
        ? preSelectedFilters.nutritionist.value
        : [],
    },
    psychologist: {
      label: "Psychologist",
      type: "multi select",
      value: preSelectedFilters?.psychologist
        ? preSelectedFilters.psychologist.value
        : [],
    },
    uvCoach: {
      label: "UV Coach",
      type: "multi select",
      value: preSelectedFilters?.uvCoach
        ? preSelectedFilters.uvCoach.value
        : [],
    },
    crm: {
      label: "CRM",
      type: "multi select",
      value: preSelectedFilters?.crm ? preSelectedFilters.crm.value : [],
    },
    packPurchaseDate: {
      label: "Pack Purchase Date",
      type: "date picker",
      datePickerProps: {
        value: preSelectedFilters?.packPurchaseDate?.value
          ? moment(preSelectedFilters.packPurchaseDate.value).format(
              "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ"
            )
          : moment().toDate(),
      },
    },
    hc: {
      label: "Health Coach",
      type: "multi select",
      value: preSelectedFilters?.hc ? preSelectedFilters.hc.value : [],
    },
    week: {
      label: "Weeks",
      type: "multi select",
      value: preSelectedFilters?.week ? preSelectedFilters.week.value : [],
    },
    deviceStatus: {
      label: "Device Status",
      type: "multi select",
      value: preSelectedFilters?.deviceStatus
        ? preSelectedFilters.deviceStatus.value
        : [],
    },
    bodyParts: {
      label: "Body Parts",
      type: "multi select",
      value: preSelectedFilters?.bodyParts
        ? preSelectedFilters.bodyParts.value
        : [],
    },
    planPurchased: {
      label: "Plan Purchased",
      type: "multi select",
      value: preSelectedFilters?.planPurchased
        ? preSelectedFilters.planPurchased.value
        : [],
    },
    paymentStatus: {
      label: "Payment Status",
      type: "multi select",
      value: preSelectedFilters?.paymentStatus
        ? preSelectedFilters.paymentStatus.value
        : [],
    },
    leadExclusionConditonStatus: {
      label: "Exclusion Condition Status",
      type: "multi select",
      value: preSelectedFilters?.leadExclusionConditonStatus
        ? preSelectedFilters.leadExclusionConditonStatus.value
        : [],
    },
    leadPrescriptionStatus: {
      label: "Prescription Status",
      type: "multi select",
      value: preSelectedFilters?.leadPrescriptionStatus
        ? preSelectedFilters.leadPrescriptionStatus.value
        : [],
    },
    leadDeliveryStatus: {
      label: "Delivery Status",
      type: "multi select",
      value: preSelectedFilters?.leadDeliveryStatus
        ? preSelectedFilters.leadDeliveryStatus.value
        : [],
    },
    amount: {
      label: "Amount",
      type: "rangeslider",
      value: preSelectedFilters?.amount
        ? preSelectedFilters.amount.value
        : [0, 50000],
    },
    packPurchaseDateRange: {
      label: "Pack Purchase",
      type: "daterange",
      value: preSelectedFilters?.packPurchaseDateRange
        ? preSelectedFilters.packPurchaseDateRange.value
        : [],
    },
  };
};

export const pastSessionStatus = [
  "Missed",
  "Completed",
  "Under Review",
  "Interrupted",
];

export const weeks = Array.from({ length: 14 }, (_, i) => `Week ${i + 1}`);

export const hc = [
  { label: "Nutritionist", value: ROLES.NUTRITIONIST },
  { label: "Psycholgist", value: ROLES.PSYCHOLOGIST },
  ,
  { label: "CRM", value: ROLES.CRM },
  { label: "UV Coach", value: ROLES.UV_COACH },
];

export const programs = [
  "Psoriasis - Device Only",
  "Psoriasis - Care Plan",
  "IBS - PLATINUM",
  "IBS - GOLD",
];

export const planPurchased = [
  "Psoriasis - Device Only",
  "Psoriasis - Care Plan",
];

export const paymentStatus = ["Initiated", "Pending", "Success", "Failure"];

export const leadExclusionConditonStatus = [
  "Excluded",
  "Passed",
  "Verification Pending",
  "None",
];

export const leadPrescriptionStatus = [
  "Verified",
  "Rejected",
  "Verification Pending",
  "None",
];

export const leadDeliveryStatus = [
  "Order Received",
  "Order Confirmed",
  "Dispatched",
  "Delivered",
  "Returned",
  "None",
];

export const preselectedOrdersFilter = {
  delivery_pending: {
    leadDeliveryStatus: {
      value: ["Order Received", "Order Confirmed", "Dispatched", "Returned"],
    },
  },
  action_required: {
    paymentStatus: {
      value: ["Failure"],
    },
    leadExclusionConditonStatus: {
      value: ["Verification Pending"],
    },
    leadPrescriptionStatus: {
      value: ["Verification Pending"],
    },
  },
  all_orders: {},
};

export const dbStatus = (item) => {
  if (item === "Under Review") return "Hold";
  if (item === "Completed") return "Completed,SessionCompleted";
  if (item === "Interrupted") return "Disrupted";
  return item;
};

export const filterStatus = (item) => {
  if (item === "Hold") return "Under Review";
  if (item === "Disrupted") return "Interrupted";
  return item;
};

export function flatmapMealTimes(obj) {
  const mealTimes = [
    "earlyMorning",
    "breakfast",
    "midDaySnack",
    "lunch",
    "eveningSnack",
    "dinner",
    "bedtime",
  ];
  let mealTimeOptions = 0;

  mealTimes.forEach((mealTime) => {
    if (Array.isArray(obj[mealTime])) {
      mealTimeOptions += obj[mealTime].length;
    }
  });

  return mealTimeOptions;
}

export const getBody = (touchPoint) => {
  return touchPoint.bodyType === "html_template" && touchPoint.bodyHtmlTemplate
    ? touchPoint.bodyHtmlTemplate.html
    : touchPoint.body;
};

export const getBodyFromHtmlTemplate = (touchPoint, htmlTemplates) => {
  if (touchPoint.bodyType === "html_template") {
    const found = htmlTemplates.find(
      (htmlTemplate) => htmlTemplate.value == touchPoint.bodyHtmlTemplateId
    );
    return found ? found.html : touchPoint.body;
  } else {
    return touchPoint.body;
  }
};

export const roleDefaultPage = (roles, user, company) => {
  const baseUrl = "/[company]/admin";
  if (
    roles.find((role) =>
      [
        "Leadership",
        "Company Admin",
        "Recruiter Manager",
        "Talent Acquisition Head",
        "HR Ops",
      ].includes(role.name)
    )
  ) {
    return {
      pathname: `${baseUrl}/dashboard`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Recruiter")) {
    return {
      pathname: `${baseUrl}/mytasks`,
      query: { company: company.slug, tab: "pending" },
    };
  }
  if (roles.find((role) => role.name === "Hiring Manager")) {
    return {
      pathname: `${baseUrl}/positions`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Campaign Admin")) {
    return {
      pathname: `${baseUrl}/campaign-templates`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Newsfeed Admin")) {
    return {
      pathname: `${baseUrl}/news-feeds`,
      query: { company: company.slug },
    };
  }
  if (roles.find((role) => role.name === "Report Admin")) {
    return {
      pathname: `${baseUrl}/dashboard`,
      query: { company: company.slug },
    };
  }
};

export const getCustomFilterObjects = (
  config,
  selectedCustomFilters,
  handleCustomAttributeChange
) => {
  const type = config.type;
  switch (type) {
    case "text":
      return {
        type: "text",
        isFilterSelected:
          !!selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value !== "",
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "integer":
    case "double":
      return {
        type: "radio",
        isFilterSelected:
          !!selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value?.type !== "all",
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "single select":
    case "multi select":
      return {
        type: "checkbox",
        isFilterSelected:
          selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value.length > 0,
        show: config.options.length > 1,
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "date":
      return {
        type: "daterange",
        isFilterSelected:
          selectedCustomFilters[config.id].value &&
          !!(
            selectedCustomFilters[config.id].value.fromDate &&
            selectedCustomFilters[config.id].value.toDate
          ),
        dateRangeProps: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
    case "boolean":
      return {
        type: "radio",
        isFilterSelected: !!selectedCustomFilters[config.id].value,
        options: getCustomFilterOptions(
          type,
          config,
          selectedCustomFilters,
          handleCustomAttributeChange
        ),
      };
  }
};
const getCustomFilterOptions = (
  type,
  config,
  selectedCustomFilters,
  handleCustomAttributeChange
) => {
  switch (type) {
    case "text":
      return [
        {
          id: config.id,
          name: config.name,
          value: selectedCustomFilters[config.id].value,
          isSelected: !!selectedCustomFilters[config.id].value,
          custom: true,
          handleChange: (e) => {
            handleCustomAttributeChange(config, e);
          },
        },
      ];
    case "integer":
    case "double":
      const labels = [
        "All",
        "Greater Than",
        "Lesser Than",
        "In Between",
        "Equal to",
      ];
      const options = [
        "all",
        "greaterThan",
        "lesserThan",
        "inBetween",
        "equalTo",
      ];
      if (type === "double") {
        options = options.filter((option) => option !== "equalTo");
      }
      return options.map((option, index) => ({
        id: option,
        name: option,
        value: option,
        isSelected: selectedCustomFilters[config.id].value?.type === option,
        handleChange: (e) => handleCustomAttributeChange(config, e),
        label: labels[index],
        custom: option !== "all",
        customConfig:
          option === "all"
            ? {}
            : option !== "inBetween"
            ? {
                inputType: "number",
                placeholder: "Enter Number",
                type: "textField",
                value:
                  option === "greaterThan" || option === "equalTo"
                    ? selectedCustomFilters[config.id].value?.min
                    : selectedCustomFilters[config.id].value?.max,
                onChange: (e) => {
                  handleCustomAttributeChange(config, e, option);
                },
              }
            : {
                minPlaceHolder: "Start",
                maxPlaceHolder: "End",
                inputType: "number",
                type: "rangeTextField",
                min: selectedCustomFilters[config.id].value?.min,
                max: selectedCustomFilters[config.id].value?.max,
                onChange: (e) => handleCustomAttributeChange(config, e, option),
              },
      }));

    case "single select":
    case "multi select":
      return config.options.map((option) => ({
        id: option.value,
        name: config.id,
        value: option.value,
        isSelected:
          selectedCustomFilters[config.id].value &&
          selectedCustomFilters[config.id].value.includes(option.value),
        handleChange: (e) => handleCustomAttributeChange(config, e),
        label: option.value,
      }));
    case "date":
      return {
        onDateChange: (e) => handleCustomAttributeChange(config, e),
        dateRange: [
          selectedCustomFilters[config.id].value.fromDate,
          selectedCustomFilters[config.id].value.toDate,
        ],
      };
    case "boolean":
      return ["true", "false"].map((option) => ({
        id: option,
        name: option,
        value: option,
        isSelected: selectedCustomFilters[config.id].value === option,
        handleChange: (e) => handleCustomAttributeChange(config, e),
        label: option === "true" ? "Yes" : "No",
      }));
  }
};

export const removeUndefinedValues = (data) => {
  const dataWithOutUndefined = {};
  Object.keys(data).forEach((key) => {
    if (data[key] !== undefined) {
      dataWithOutUndefined[key] = data[key];
    }
  });
  return dataWithOutUndefined;
};

export const getCompanyDetails = (currentUser) => {
  const companyId = getCompanyId();
  const company = currentUser.company.find(
    (company) => company.id === companyId
  );
  return company;
};

export const removeCustomAttributeEmptyValues = (data) => {
  const dataWithOutEmptyValues = {};
  data &&
    Object.keys(data).forEach((key) => {
      if (data[key] !== undefined && data[key] !== "" && data[key] !== null) {
        dataWithOutEmptyValues[key] = data[key];
      }
    });
  return dataWithOutEmptyValues;
};

export const computeAndSetPrimaryShades = async ({ document, color }) => {
  const primary50 = chroma(color).brighten(0.8).hex();
  const primary100 = chroma(color).brighten(0.6).hex();
  const primary300 = chroma(color).brighten(0.4).hex();
  const primary400 = chroma(color).brighten(0.2).hex();
  const primary500 = chroma(color).hex();
  const primary600 = chroma(color).darken(0.2).hex();
  const primary700 = chroma(color).darken(0.6).hex();
  const primary900 = chroma(color).darken(1).hex();
  document.documentElement.style.setProperty("--primary-color-50", primary50);
  document.documentElement.style.setProperty("--primary-color-100", primary100);
  document.documentElement.style.setProperty("--primary-color-300", primary300);
  document.documentElement.style.setProperty("--primary-color-400", primary400);
  document.documentElement.style.setProperty("--primary-color-500", primary500);
  document.documentElement.style.setProperty("--primary-color-600", primary600);
  document.documentElement.style.setProperty("--primary-color-700", primary700);
  document.documentElement.style.setProperty("--primary-color-900", primary900);
};

export const getEmojiSrc = (circleValue) => {
  if (circleValue === "disengaged") return "/images/icons/sad-emoji-ac.svg";
  if (circleValue === "low" || circleValue === "negative")
    return "/images/icons/negative.svg";
  if (circleValue === "medium" || circleValue === "neutral")
    return "/images/icons/neutral.svg";
  if (circleValue === "high" || circleValue === "positive")
    return "/images/icons/positive.svg";
  else {
    if (circleValue > 67) return "/images/icons/love-emoji-ac.svg";
    if (circleValue > 33 && circleValue <= 66)
      return "/images/icons/happy-emoji-ac.svg";
    return "/images/icons/sad-emoji-ac.svg";
  }
};
export const getEmojiText = (circleValue) => {
  if (Number.isInteger(Number(circleValue)) || circleValue === "disengaged") {
    if (circleValue > 67) return "Positive";
    if (circleValue > 33 && circleValue <= 66) return "Neutral";
    return "Negative";
  }
  if (circleValue === "low") return "Negative";
  if (circleValue === "medium") return "Neutral";
  if (circleValue === "high") return "Positive";
  return circleValue;
};

export const getHideScrollBarCss = () => {
  return `
    .u_body {
        -ms-overflow-style: none;  /* Internet Explorer 10+ */
        scrollbar-width: none;  /* Firefox */
    }
    .u_body::-webkit-scrollbar { 
        display: none;  /* Safari and Chrome */
    }
  `;
};
export const getCustomFedexCss = (companyId) => {
  const originUrl = window.location.origin;
  if (
    companyId === "e7a809d1-79ba-4c05-a82a-da1446f5e259" ||
    companyId === "df0f4079-94d9-4467-9e0e-55d8f2b8a82b"
  ) {
    return `
    ${getHideScrollBarCss()}
    @font-face {
        font-family: 'FedEx Sans';
        src: url('${originUrl}/fonts/FedExSans_Rg.ttf') format('truetype');
      }
      @font-face {
        font-family: 'FedEx Sans Light';
        src: url('${originUrl}/fonts/FedExSans_lt.ttf') format('truetype');
      }
      * {
        font-family: 'FedEx Sans', sans-serif !important;
      }
      h1, h2, h3, h4 {
        font-family: 'FedEx Sans Light', sans-serif !important;
      }`;
  }

  if (companyId === "dd58e4cc-44a6-4caa-b302-363e15ba9a8c") {
    return `
    ${getHideScrollBarCss()}
    @font-face {
        font-family: 'DS Indigo';
        src: url('${originUrl}/fonts/dsindigo-regular.woff2') format('woff2') , url('${originUrl}/fonts/dsindigo-regular.woff') format('woff');
      }

      * {
        font-family: 'DS Indigo', sans-serif !important;
      }
    `;
  }

  return "";
};

export const isValidToRun = (task) => {
  let invalidTokens = [
    "CANDIDATE_NAME",
    "POSITION_TITLE",
    "DOJ",
    "COMPANY_NAME",
    "RECRUITER_NAME",
    "HIRING_MANAGER_NAME",
    "COMPANY_ADDRESS",
    "CANDIDATE_ADDRESS",
    "TOUCHPOINT_TITLE",
  ];
  if (task.type === "meeting") {
    invalidTokens = [
      ...invalidTokens,
      "MEETING_TYPE",
      "MEETING_PERSON",
      "MEETING_LINK",
      "MEETING_LOCATION",
      "MEETING_DATE",
    ];
  }
  const validPlaceHolders = task.placeholders.filter(
    (field) => !invalidTokens.includes(field)
  );
  const isValid = validPlaceHolders.reduce((accumulator, currentValue) => {
    return (
      accumulator && task[snakeToCamel(currentValue.toLowerCase())] !== null
    );
  }, true);
  return isValid;
};

export const computeThemeColors = async (company) => {
  const { theme } = company;
  if (theme?.headerType === "custom") {
    const headerColor = theme.headerColor;
    document.documentElement.style.setProperty("--header", headerColor);
  }
  if (theme?.ctaType === "custom") {
    const primaryColor = theme.ctaColor;
    await computeAndSetPrimaryShades({ color: primaryColor, document });
  }
  if (theme?.taskCardType === "custom") {
    if (theme?.taskCardColor.includes("linear-gradient")) {
      document.documentElement.style.setProperty(
        "--stage-overlay",
        theme.taskCardColor
      );
    } else {
      document.documentElement.style.setProperty(
        "--stage-solid",
        theme.taskCardColor
      );
    }
  }
};

export const getURL = (endpoint) => {
  if (process.env.NODE_ENV === "development") {
    return `http://localhost:4200/${endpoint}`;
  }
  if (process.env.NODE_ENV === "qa") {
    return `https://can-engage-qa.napses.in/${endpoint}`;
  }
  if (process.env.NODE_ENV === "preprod") {
    return `https://uat.loop.ansr.com/${endpoint}`;
  }
  if (process.env.NODE_ENV === "prod") {
    return `https://loop.ansr.com/${endpoint}`;
  }
  if (process.env.NODE_ENV === "automation") {
    return `https://automation.canengage.ansr.com/${endpoint}`;
  }
  return `https://can-engage.napses.in/${endpoint}`;
};

export const getValueByPath = (obj, path, defaultReturn = null) => {
  const properties = path.split(".");
  let value = obj;

  for (let prop of properties) {
    if (value && value.hasOwnProperty(prop)) {
      value = value[prop];
    } else {
      // Path does not exist, return null
      return defaultReturn;
    }
  }
  // Return the value at the path
  return value;
};

export function createWeeksArray(startDate, endDate) {
  const weeksArray = [];
  let currentWeek = [];
  const currentDate = moment(startDate);
  const lastDate = moment(endDate);
  while (currentDate.isBefore(lastDate) || currentDate.isSame(lastDate)) {
    currentWeek.push(currentDate.format("YYYY-MM-DD"));

    if (currentWeek.length === 7) {
      weeksArray.push(currentWeek);
      currentWeek = [];
    }
    currentDate.add(1, "days");
  }
  if (currentWeek.length > 0) {
    weeksArray.push(currentWeek);
  }
  return weeksArray;
}

export const getPhaseName = (data, startPhase) => {
  console.log(data);
  let currentDate = new Date().setHours(0, 0, 0, 0);
  for (let entry of data) {
    let startDate = new Date(entry.startDate).setHours(0, 0, 0, 0);
    let endDate = new Date(entry.endDate).setHours(0, 0, 0, 0);

    if (startDate <= currentDate && currentDate <= endDate) {
      return entry.Phase.name;
    }
  }
  return startPhase ? startPhase : "Yet to start";
};

export const isValidImageFormat = (format) => {
  return ["jpg", "jpeg", "png", "JPG", "JPEG", "PNG"].includes(format);
};
export const isValidVideoFormat = (format) => {
  return ["mp4", "mov", "MP4", "MOV"].includes(format);
};
export const isValidFileFormat = (format) => {
  return ["pdf", "PDF", "txt", "TXT", "doc", "DOC", "docx", "DOCX"].includes(
    format
  );
};

export const uploadBase64ImageToStorage = (base64String) => {
  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  return dataURItoBlob(base64String);
};

export const formatFileSize = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["bytes", "kb", "mb", "gb", "tb"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return (
    parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + " " + sizes[i]
  );
};

export const isHcAdmin = (name) => {
  return ["nutritionist", "psychologist", "uv coach", "crm"].includes(name);
};

export const isAdmin = (name) => {
  return ["nutritionist", "psychologist", "admin", "crm", "uv coach"].includes(
    name
  );
};

const pageRedirectAfterLogin = {
  admin: "/admin/users",
  nutritionist: "/hc",
  psychologist: "/hc",
  crm: "/crm/patients",
  "uv coach": "/hc",
  "psoriasis admin": "/orders",
  doctor: "/doctor-panel/patients",
  kam: "/doctor-panel/doctors"
};

export const redirectToPage = (role) => {
  return pageRedirectAfterLogin[role];
};
export const dietPlan = [
  {
    label: "Early Morning",
    value: "earlyMorning",
    options: 3,
  },
  {
    label: "Breakfast",
    value: "breakfast",
    options: 5,
  },
  {
    label: "Mid Day Snack",
    value: "midDaySnack",
    options: 3,
  },
  {
    label: "Lunch",
    value: "lunch",
    options: 5,
  },
  {
    label: "Evening Snack",
    value: "eveningSnack",
    options: 3,
  },
  {
    label: "Dinner",
    value: "dinner",
    options: 5,
  },
  {
    label: "Bedtime",
    value: "bedtime",
    options: 3,
  },
];

export const comorbidities = [
  "Diabetes",
  "Hypertension",
  "Obesity",
  "IBS/IBD",
  "PCOS/PCOD",
  "Other",
];
export const eatingHabits = [
  {
    label: "Vegetarian",
    value: "Veg",
  },
  {
    label: "Non-vegetarian",
    value: "NonVeg",
  },
  {
    label: "Vegan",
    value: "Vegan",
  },
  {
    label: "Eggetarian",
    value: "Egg",
  },
];
export const activityLevels = [
  {
    label: "Sedentary",
    value: "Sedentary (little or no exercise)",
  },
  {
    label: "Lightly Active",
    value: "Lightly active (light exercise/sports 1-3 days/week)",
  },
  {
    label: "Moderately Active",
    value: "Moderately active (moderate exercise/sports 3-5 days/week)",
  },
  {
    label: "Very Active",
    value: "Very active (hard exercise/sports 6-7 days a week)",
  },
  {
    label: "Super Active",
    value: "Super active (very hard exercise & physical job or 2x training)",
  },
];
export const cuisines = [
  {
    label: "Assamese_Oriya_Bengali",
    value: "Assamese_Oriya_Bengali",
  },
  {
    label: "Mughlai_Awadhi_Bihari_Uttarpradesh",
    value: "Mughlai_Awadhi_Bihari_Uttarpradesh",
  },
  {
    label: "Andhra_Telugu_Telangana_Hyderabadi",
    value: "Andhra_Telugu_Telangana_Hyderabadi",
  },
  {
    label: "Gujarati_sindhi_Rajasthani",
    value: "Gujarati_sindhi_Rajasthani",
  },
  {
    label: "Maharastrian_goan_malwani_Konkani",
    value: "Maharastrian_goan_malwani_Konkani",
  },
  {
    label: "Kerala",
    value: "Kerala",
  },
  {
    label: "Tamil_Chettinad",
    value: "Tamil_Chettinad",
  },
  {
    label: "Karnataka_Coorgi_Coastal Karnataka",
    value: "Karnataka_Coorgi_Coastal Karnataka",
  },
  {
    label: "Kashmiri_Punjabi",
    value: "Kashmiri_Punjabi",
  },
];
export const allergies = [
  "Dairy",
  "Eggs",
  "Tree_Nuts",
  "Nuts",
  "Soy",
  "Shell_Fish",
  "Fish",
  "Sesame",
  "Sea_Food",
  "Wheat",
  "None",
];

export const getScheduledDateTimeSlot = (date, time) => {
  if (!date || !time) return "";
  return `${moment(date).format("D MMM YYYY")} ${moment(time, "HH:mm").format(
    "h:mm A"
  )}`;
};

export const fetchFirebaseData = async (entity, id, column, updateData) => {
  const q = query(
    collection(db, entity),
    where("crmId", "==", id),
    where(column, "==", true)
  );
  const snapShot = await getCountFromServer(q);
  updateData(snapShot.data()?.count);
  // const fetchFirebaseDataUnsubscribe = onSnapshot(q, async (querySnapshot) => {
  //   const result = [];
  //   for (const document of querySnapshot.docs) {
  //     const firebaseData = document.data();
  //     result.push({
  //       ...firebaseData,
  //     });
  //   }
  //   updateData([...result]);
  // });
  // return () => fetchFirebaseDataUnsubscribe();
};

export const isCrm = (role) => {
  return role === "crm";
};

export const isUvCoach = (role) => {
  return role === "uv coach";
};
export const isUvCoachOrNutritionist = (role) => {
  return role === "uv coach" || role === "nutritionist";
};

export const isAdminRole = (role) => {
  return role === "admin";
};

export const getQueryParamByName = (name, url = window.location.href) => {
  const params = new URLSearchParams(new URL(url).search);
  return params.get(name);
};

export const netcoreTrackEvent = (eventName, payload) => {
  smartech("dispatch", eventName, {
    ...payload,
  });
};

export const PSORIASIS_DISEASE_ID = "8295d172-f2ee-4596-a35f-9186486b4f16";
export const IBS_DISEASE_ID = "f8c5e4c3-cd3b-4fb6-9209-b4c7067bd400";
export const patientUvSessionActionString = (actionType) => {
  const actionTypeLabel = {
    CONTINUE_WITH_SAME_DOSAGE: "Continue with same dosage",
    REDUCE_DOSAGE_BY_HALF: "Reduce dosage by 50%",
  };
  return actionTypeLabel[actionType];
};

export const formatSurveyResults = (patientSurveys, week) => {
  let finalSurveyResult = [];
  let surveyResults =
    patientSurveys?.data?.entity?.find((survey) => survey.week === week)
      ?.answers || [];
  if (
    week !== 1 &&
    surveyResults.find(
      (answer) => answer.label === SKIN_TYPE_QUESTION_LABEL
    ) === undefined
  ) {
    const week1SurveyAns =
      patientSurveys?.data?.entity?.find((survey) => survey.week === 1)
        ?.answers || [];
    const skinTypeAnsObject = week1SurveyAns.find(
      (answer) => answer.label === SKIN_TYPE_QUESTION_LABEL
    );
    surveyResults = [{ ...skinTypeAnsObject }, ...surveyResults];
  }
  surveyResults.forEach((surveyResult) => {
    let obj = { ...surveyResult };
    if (surveyResult.label === SKIN_TYPE_QUESTION_LABEL) {
      if (obj.answer.value === null && week !== 1)
        obj = patientSurveys?.data?.entity
          ?.find((survey) => survey.week === 1)
          .answers.find((ans) => ans.label === SKIN_TYPE_QUESTION_LABEL);
      finalSurveyResult.push(obj);
    }
  });
  surveyResults.forEach((surveyResult) => {
    let obj = { ...surveyResult };
    if (surveyResult.label === "Where do you have patches on your body?") {
      if (obj.answer.valueList.length === 0 && week !== 1)
        obj = patientSurveys?.data?.entity
          ?.find((survey) => survey.week === 1)
          .answers.find(
            (ans) => ans.label === "Where do you have patches on your body?"
          );
      finalSurveyResult.push(obj);
    }
  });
  surveyResults.forEach((surveyResult) => {
    let obj = { ...surveyResult };
    if (
      surveyResult.label !== SKIN_TYPE_QUESTION_LABEL &&
      surveyResult.label !== "Where do you have patches on your body?"
    ) {
      finalSurveyResult.push(obj);
    }
  });
  return finalSurveyResult;
};

export const getPatientAnswers = (data, idx, week) => {
  if (week !== 1) {
    if (data.answer.value === null && data?.questionType === "Slider")
      return "N/A";
    if (data.answer.value === null && data.answer.valueList.length === 0)
      return "N/A";
  }
  if (
    data.answer.value === null &&
    data?.questionType === "Slider" &&
    data.skipped
  )
    return "Skipped";
  if (data.answer.value === null && data?.questionType === "Slider") return 0;
  if (data.answer.value === null && data.answer.valueList.length === 0)
    return "Skipped";
  if (data?.questionType === "PatchSelection") {
    return (
      <div>
        {data?.answer?.valueList?.map((key, index, array) => {
          return (
            <p>
              {key?.displayName}
              {idx === 2 ? ` - ${key?.measure} Palms` : ""}
            </p>
          );
        })}
      </div>
    );
  } else if (data?.questionType === "SingleSelect") {
    return data?.answer?.label;
  } else if (data?.questionType === "BMI") {
    return JSON.stringify({
      bmi: data?.answer?.value,
      height: convertCmIntoFeet(data?.answer?.valueList?.[0]?.height),
      weight: data?.answer?.valueList?.[0]?.weight,
    });
  } else if (data?.questionType === "Slider") {
    return data?.answer?.value;
  }

  return data?.answer?.value;
};

export const skinType = {
  1: {
    label: "Type 1",
    value: 1,
  },
  2: {
    label: "Type 2",
    value: 2,
  },
  3: {
    label: "Type 3",
    value: 3,
  },
  4: {
    label: "Type 4",
    value: 4,
  },
  5: {
    label: "Type 5",
    value: 5,
  },
  6: {
    label: "Type 6",
    value: 6,
  },
};

export const SKIN_TYPE_QUESTION_SLUG = "QOP001";
export const SKIN_TYPE_QUESTION_LABEL = "What is your skin type?";

export const getPSOScore = (score) => {
  if (score === -1) return "<40";
  return score;
};

export const getRole = (role) => {
  const roles = {
    nutritionist: "Nutritionist",
    psychologist: "Psychologist",
    "uv coach": "UV Coach",
  };
  return roles[role];
};

export const getHistory = (data, idx) => {
  if (data?.questionType === "PatchSelection") {
    if (
      !data.responseHistory?.["patches"].length &&
      !data.responseHistory?.["skinCoveredPatches"].length
    )
      return <Text>No responses added to the question</Text>;
    return (
      idx === 1
        ? data.responseHistory?.["patches"]
        : data.responseHistory?.["skinCoveredPatches"]
    )
      .sort(
        (value1, value2) => moment(value2.createdAt) - moment(value1.createdAt)
      )
      .map((item, index) => (
        <div
          key={index}
          className="border-b border-neutral-600 border-b-none py-4 last:border-none first:pt-0"
        >
          <Text className="mb-3">
            Modified on {moment(item.createdAt).format("Do-MMM-YYYY")}{" "}
            {moment(item.createdAt).format("hh:mm A")}
          </Text>
          <Text>Modified By: {item?.createdBy ?? ""}</Text>
          {idx === 12 ? (
            <>
              <Text>BMI {item.answer?.bmi}</Text>
              <Text>Height {convertCmIntoFeet(item.answer?.height)}</Text>
              <Text>Weight {item.answer?.weight}</Text>
            </>
          ) : (
            <Text>
              {idx === 1
                ? item.answer.map((key) => {
                    return (
                      <div className="flex flex-col">{key.displayName}</div>
                    );
                  })
                : item.answer.map((key) => {
                    return (
                      <div className="flex flex-col">
                        {key.displayName} - {key.measure}
                      </div>
                    );
                  })}
            </Text>
          )}
        </div>
      ));
  }
  return data.responseHistory?.length ? (
    data.responseHistory
      .sort(
        (value1, value2) => moment(value2.createdAt) - moment(value1.createdAt)
      )
      .map((item, index) => (
        <div
          key={index}
          className="border-b border-neutral-600 border-b-none py-4 last:border-none first:pt-0"
        >
          <Text className="mb-3">
            Modified on {moment(item.createdAt).format("Do-MMM-YYYY")}{" "}
            {moment(item.createdAt).format("hh:mm A")}
          </Text>
          <Text>Modified By: {item?.createdBy ?? ""}</Text>
          {idx === 12 ? (
            <>
              <Text>BMI {item.answer?.bmi}</Text>
              <Text>Height {convertCmIntoFeet(item.answer?.height)}</Text>
              <Text>Weight {item.answer?.weight}</Text>
            </>
          ) : (
            <Text>{item.answer}</Text>
          )}
        </div>
      ))
  ) : (
    <Text>No responses added to the question</Text>
  );
};

export const showAddOrEditBasedOnRoleForSkinHealthIndex = (
  role,
  idx,
  patientDetails,
  week,
  count
) => {
  if (count > 0 && week === 1 && idx < 2) return false;
  if (idx < 2 && week !== 1) return false;
  if (role === "admin") return false;
  if (role === "psychologist") return false;
  if (role === "crm") {
    if (
      patientDetails?.data?.entity?.PatientPlan?.Plan?.name ===
      planName.deviceOnly
    ) {
      if (idx > 2 && idx < 13) return true;
      return false;
    } else {
      if (idx > 2 && idx < 12) return true;
      return false;
    }
  }
  if (role === "uv coach") {
    if (idx < 3) return true;
    return false;
  }
  if (role === "nutritionist") {
    if (idx === 12) return true;
    return false;
  }
  return false;
};

export const calculateBMI = (heightFeet, weightKg) => {
  // Convert height from feet to meters
  const heightMeters = heightFeet * 0.3048;
  // Calculate BMI
  const bmi = weightKg / (heightMeters * heightMeters);
  return bmi.toFixed(2); // Round BMI to 2 decimal places
};

export const getPatientComments = (data, setDetails, idx) => {
  if (idx === 1) {
    const comments = data?.comments?.["patches"]?.length
      ? data?.comments?.["patches"]?.reverse()
      : [];
    setDetails(comments);
    return;
  } else if (idx === 2) {
    const comments = data?.comments?.["skinPatches"]?.length
      ? data?.comments?.["skinPatches"]?.reverse()
      : [];
    setDetails(comments);
    return;
  }
  const comments = data?.comments?.length ? data?.comments?.reverse() : [];
  setDetails(comments);
};

export const showViewSkinHealthCareComment = (role, data, idx) => {
  if (idx === 1) return data?.comments?.["patches"]?.length;
  if (idx === 2) return data?.comments?.["skinPatches"]?.length;
  return data?.comments?.length;
};

export const showAddSkinHealthCareComment = (role, data, idx) => {
  if (role === "admin") return false;
  return true;
};

export const getMessagesForAdherence = (role, adherence) => {
  const message = {
    admin: {
      "uv therapy": "The user has not completed any sessions yet",
    },
    nutritionist: {
      "uv therapy": "UV Therapy not started",
    },
    "uv coach": {
      "uv therapy": "UV Therapy not started",
    },
    crm: {
      "uv therapy": "UV Therapy not started",
    },
    psychologist: {
      "uv therapy": "UV Therapy not started",
    },
  };
  return message?.[role]?.[adherence] || "";
};

export const getTimelineWeek = (startDate, loggedDate) => {
  const daysDifference = loggedDate.diff(startDate, "days") + 1;
  const weeksDifference = Math.ceil(daysDifference / 7);
  return weeksDifference > 0 ? weeksDifference : 0;
};

export const hcNotificationMapping = {
  [ROLES.ADMIN]: "seenByAdmin",
  [ROLES.CRM]: "seenByCrm",
  [ROLES.NUTRITIONIST]: "seenByNutritionist",
  [ROLES.PSYCHOLOGIST]: "seenByPsychologist",
  [ROLES.UV_COACH]: "seenByUvCoach",
};

export const checkNewPatient = (data, showNewPatients) => {
  if (data?.User?.offboardedAt != null)
    return "after:bg-error-100 after:opacity-30";
  if (!showNewPatients) return "hover:after:bg-neutral-200 after:bg-white";
  if (
    data?.PatientOnboardingAlert &&
    data?.PatientOnboardingAlert[hcNotificationMapping[getAuth().role]] ===
      false
  )
    return "after:bg-success-100 after:opacity-30";
  return "hover:after:bg-neutral-200 after:bg-white";
};

export const planName = {
  carePlan: "Care Plan",
  deviceOnly: "Device Only",
};
export const patientNotificationType = {
  [ROLES.ADMIN]: "patients",
  [ROLES.CRM]: "crm_patients",
  [ROLES.NUTRITIONIST]: "nutritionist_patients",
  [ROLES.PSYCHOLOGIST]: "psychologist_patients",
  [ROLES.UV_COACH]: "uv_coach_patients",
};

export const getChatId = {
  [ROLES.UV_COACH]: 1,
  [ROLES.NUTRITIONIST]: 2,
  [ROLES.PSYCHOLOGIST]: 3,
};

export const getCoachType = {
  [ROLES.UV_COACH]: "uvCoach",
  [ROLES.NUTRITIONIST]: "nutritionist",
  [ROLES.PSYCHOLOGIST]: "psychologist",
};
