import * as yup from "yup";

export const options = [
  {
    label: "0",
    value: 0,
  },
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
];

export const defaultValue = {
  0: {
    label: "0",
    value: 0,
  },
  1: {
    label: "1",
    value: 1,
  },
  2: {
    label: "2",
    value: 2,
  },
  3: {
    label: "3",
    value: 3,
  },
  4: {
    label: "4",
    value: 4,
  },
  5: {
    label: "5",
    value: 5,
  },
};

export const getDefaultAnswerValue = (data, id) => {
  if (id === 12) {
    return data?.answer?.value === null
      ? { height: "", weight: "" }
      : data?.answer?.valueList[0];
  }
  return data?.answer?.value
    ? defaultValue[data.answer.value]
    : defaultValue[0];
};

export const getSkinHealthScore = (data, idx, setDetails) => {
  const details = {
    3: {
      modalTitle: "1.How severe is your skin condition as of today?",
      name: "skinCondition",
      placeholder: "How severe is your skin condition as of today?",
      options: options,
      validator: {
        skinCondition: yup.string().required("skinCondition is mandatory!"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    4: {
      modalTitle: "2.How self-conscious do you feel because of your condition?",
      name: "condition",
      placeholder: "How self-conscious do you feel because of your condition?",
      options: options,
      validator: {
        condition: yup.string().required("condition is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    5: {
      modalTitle: "3.How itchy has your skin felt over the past 7 days?",
      name: "itchyCondition",
      placeholder: "How itchy has your skin felt over the past 7 days?",
      options: options,
      validator: {
        itchyCondition: yup.string().required("itchyCondition is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    6: {
      modalTitle:
        "4.How thick have the patches on your skin been over the past 7 days?",
      name: "patches",
      placeholder:
        "How thick have the patches on your skin been over the past 7 days?",
      options: options,
      validator: {
        patches: yup.string().required("patches is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    7: {
      modalTitle:
        "5.How red have the patches on your skin been over the past 7 days?",
      name: "redness",
      placeholder:
        "How red have the patches on your skin been over the past 7 days?",
      options: options,
      validator: {
        redness: yup.string().required("redness is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    8: {
      modalTitle: "6.Work / Education",
      name: "workEducation",
      placeholder: "Work / Education",
      options: options,
      validator: {
        workEducation: yup.string().required("workEducation is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    9: {
      modalTitle: "7.Social Life",
      name: "socialLife",
      placeholder: "Social Life",
      options: options,
      validator: {
        socialLife: yup.string().required("socialLife is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    10: {
      modalTitle: "8.Personal relationships",
      name: "personalRelationships",
      placeholder: "Personal relationships",
      options: options,
      validator: {
        personalRelationships: yup
          .string()
          .required("personal relationships is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
    11: {
      modalTitle: "9.Sleep",
      name: "sleep",
      placeholder: "Sleep",
      options: options,
      validator: {
        sleep: yup.string().required("sleep is required"),
      },
      defaultValue: getDefaultAnswerValue(data),
    },
  };

  setDetails({ ...details[idx], data });
};
export const PSORIASIS_DISEASE_ID = "8295d172-f2ee-4596-a35f-9186486b4f16";
export const IBS_DISEASE_ID = "f8c5e4c3-cd3b-4fb6-9209-b4c7067bd400";
export const getPlanNameByDiseaseId = (planName, diseaseId) => {
  const planNameWithDisease = {
    [PSORIASIS_DISEASE_ID]: {
      name: `Psoriasis - ${planName}`,
    },
    [IBS_DISEASE_ID]: {
      name: `IBS - ${planName}`,
    },
  };
  return planNameWithDisease[diseaseId]?.name || "";
};

export const convertCmIntoFeet = (value) => {
  const inches = value * 0.393701;
  const feet = inches / 12;
  return feet.toFixed(2);
};

export const convertFeetIntoCm = (value) => {
  const cm = value * 30.48;
  return cm.toFixed(2);
};

export const leadExclusionConditionStatus = {
  excluded: "Excluded",
  passed: "Passed",
  verification_pending: "Verification Pending",
};

export const leadPrescriptionStatus = {
  verified: "Verified",
  rejected: "Rejected",
  verification_pending: "Verification Pending",
};

export const leadDeliveryStatus = {
  order_received: "Order Received",
  order_confirmed: "Order Confirmed",
  dispatched: "Dispatched",
  delivered: "Delivered",
  returned: "Returned",
};
export const SIDEBAR_ROLES = {
  nutritionist: "nutritionist",
  psychologist: "psychologist",
  "uv coach": "uv coach",
  crm: "crm",
  admin: "admin",
  "psoriasis admin": "back office",
  doctor: "doctor",
  kam: "kam"
};

export const leadExclusionConditonStatus = {
  excluded: "Excluded",
  passed: "Passed",
  verification_pending: "Verification Pending",
};

export const leadPrescriptionsStatus = {
  verified: "Verified",
  rejected: "Rejected",
  verification_pending: "Verification Pending",
};

export const formattedAddress = (address) => {
  return `${address?.address},\n ${address?.city} - ${address?.pincode}, ${address?.state}`;
};

export const phases = {
  GUT_CLEANSE: "Gut Cleanse",
  SUSTENANCE: "Sustenance",
  REINTRODUCTION: "Reintroduction",
  ENROLLMENT: "Enrollment",
  FULL_PROGRAM_EXTENSION: "Full Program Extension",
  NUTRITIONIST_SUPPORT_EXTENSION: "Nutritionist support extension",
  PSYCHOLOGY_SUPPORT_EXTENSION: "Psychology support Extension",
};
